import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'

import Layout from '../components/layout'
import Seo from "../components/seo";

import { SectionContainer } from "../components/primitives";

import FaPlus from '../assets/svg/plus-solid.inline.svg';
import FaMinus from '../assets/svg/minus-solid.inline.svg';

import { slugify } from "../components/utils";

import './faq.scss'

const FAQPage = ({ data, location }) => {
    const {
        allContentfulFaqCategories,
        allContentfulFaqQuestions,
    } = data

    const categories = allContentfulFaqCategories.edges;
    const questions = allContentfulFaqQuestions.nodes;

    const [activeIndex, setActiveIndex] = useState(null)

    const clickBtn = (index, groupName) => {

        if(activeIndex === index) {
            setActiveIndex(null)
        } else {
            setActiveIndex(index)
        }
    }

    useEffect(() => {
        console.log('here')
        console.log(activeIndex)

        if(activeIndex) {
            const element = document.getElementById(activeIndex.toString());
            element.scrollIntoView({behavior: "smooth", block: "start"});
        }

    }, [activeIndex])



    return (
        <Layout className='faq-page'>
            <Seo title='Frequently Asked Questions'/>
            <SectionContainer className='top-categories-section' bgColor='white'>
                <h1 className='page-header'>Frequently Asked Questions</h1>
                <div className='accordion-box'>
                    {categories.map(({node}, index) => {
                        const isActive = index === activeIndex;
                        const bodyDisplay = isActive ? 'block' : 'none';
                        const categoryName = node?.groupName ?? '';
                        const icon = isActive ? <FaMinus className='faq-icon'/> : <FaPlus className='faq-icon'/>;
                        return(
                            <div key={`${slugify(categoryName)}-accordion-panel`} id={categoryName}>
                                <div id={index.toString()} role="button" className='accordion-btn' onClick={() => clickBtn(index, categoryName)}>
                                    <h3 className='group-name'>{categoryName}</h3> {icon}
                                </div>
                                {questions.filter(({faqQuestion}) => faqQuestion.groupName === categoryName).map( ({answer, question}, index) => (
                                    <div key={`question-${index}`} className='accordion-body' style={{display: bodyDisplay}}>
                                        <p className='question'>{question}</p>
                                        <div className='answer' dangerouslySetInnerHTML={{__html: answer.childMarkdownRemark.html}}/>
                                    </div>
                                ))}
                            </div>
                        )
                    })}
                </div>
            </SectionContainer>
        </Layout>
    )
}

FAQPage.propTypes = {
    data: PropTypes.shape({
        allContentfulFaqCategories: PropTypes.object,
        allContentfulFaqQuestions: PropTypes.object,
    }),
    location: PropTypes.object
}

export const FAQPageQuery = graphql`
  query {
    allContentfulFaqCategories(sort: {fields: faq_questions___faqQuestion___sort}) {
    edges {
      node {
        groupName
        sort
      }
    }
  }
  allContentfulFaqQuestions(filter: {faqQuestion: {groupName: {ne: null}}}) {
    nodes {
      question
      answer {
        childMarkdownRemark {
          html
        }
      }
      faqQuestion {
        groupName
      }
    }
  }
  }
`

export default FAQPage
